import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const HybridExample = makeShortcode("HybridExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "powerful-animation-with-transitions-and-game-loops-combined"
    }}>{`Powerful animation with transitions and game loops combined`}</h1>
    <Vimeo id={438210687} mdxType="Vimeo" />
    <p>{`Check this out!`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/hzKOMXi.gif",
        "alt": null
      }}></img></p>
    <p>{`It's a donut chart that transitions to a bar chart. Same component, same React
code, different radius. 29,905px instead of 728px. 🤯`}</p>
    <p>{`I `}<em parentName="p">{`actually`}</em>{` got the idea from that flat earth documentary on Netflix. Behind
the Curve. No joke.`}</p>
    <p>{`Why do flat earth people believe the earth is flat?`}</p>
    <p>{`It's because the curve is soooooooo big that it looks flat.`}</p>
    <p>{`That gave me an idea for a client project. They asked me to build a smooth
transition from a donut-shaped visualization to a bar chart shape.`}</p>
    <p>{`No idea how to do that so I stalled for weeks by building everything else
first. Until one day I had an idea`}</p>
    <blockquote className="twitter-tweet" data-lang="en">
  <p lang="en" dir="ltr">
    I just discovered a new approach to React + D3 transitions 🤯 Of course this
    happens juuuust when I think React for Data Visualization is complete
    <br />
    <br />
    Check this out, it combines the &quot;game loop via state changes&quot; with
    the &quot;D3 runs transitions&quot; approach <a href="https://t.co/mMglR4hDwp">
      pic.twitter.com/mMglR4hDwp
    </a>
  </p>
  &mdash; Swizec Teller (@Swizec) <a href="https://twitter.com/Swizec/status/1102818340991430656?ref_src=twsrc%5Etfw">March 5, 2019</a>
    </blockquote>
    <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
    <p>{`You can `}<em parentName="p">{`combine the two approaches to animation`}</em>{` you learned in React for Data
Visualization. We talked about`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`The game loop approach.`}</strong>{` Borrows ideas from the gaming industry. Call
`}<inlineCode parentName="p">{`this.setState`}</inlineCode>{` 60 times per second and your React component animates.
Wonderful.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`The D3 transition approach.`}</strong>{` You take a React component, use props as a
staging area, render from state, and use `}<inlineCode parentName="p">{`componentDidMount`}</inlineCode>{` or `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{`
to hand over rendering control to D3. D3 runs its transition, manipulates
the DOM, then gives control back to React.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "a-new-approach-to-complex-transitions"
    }}>{`A new approach to complex transitions`}</h2>
    <p>{`Now there's a 3rd approach. Something I never thought of before and it works
great.`}</p>
    <p>{`Let's look at the transition again`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/hzKOMXi.gif",
        "alt": null
      }}></img></p>
    <p>{`Here's what happens behind the scenes to make it work:`}</p>
    <ol>
      <li parentName="ol">{`A `}<inlineCode parentName="li">{`<Donut>`}</inlineCode>{` component renders a bunch of `}<inlineCode parentName="li">{`<Arc>`}</inlineCode>{`s`}</li>
      <li parentName="ol">{`Each `}<inlineCode parentName="li">{`<Arc>`}</inlineCode>{` takes care of its complex shape. Built from 3 different D3 arc
generators`}</li>
      <li parentName="ol">{`The whole thing is driven by D3's pie generator. It calculates start and end
angles for individual arcs`}</li>
      <li parentName="ol">{`Donut size is a function of radius. The bigger the radius, the bigger the
donut.`}</li>
      <li parentName="ol">{`As the radius increases from 728 to 29,905 the donut grows`}</li>
      <li parentName="ol">{`And it moves down as fast as it grows. Without moving, it would fly off the
screen and you'd never see it again`}</li>
      <li parentName="ol">{`To keep the pie exactly 728px wide even though it's rendered on a 29,905px
donut ... well you gotta calculate
`}<a parentName="li" {...{
          "href": "https://planetcalc.com/1421/"
        }}>{`the arc segment`}</a>{` and derive start and end
angles from that`}</li>
    </ol>
    <p>{`🤯`}</p>
    <p>{`That's a lot of stuff.`}</p>
    <p>{`And it's all driven by this code. The transition 👇`}</p>
    <p><img parentName="p" {...{
        "src": "https://pbs.twimg.com/media/D03-2wtU8AASbl-.jpg",
        "alt": null
      }}></img></p>
    <p>{`I call this on component mount. Could be on a click event or whatever. Starts a
custom tween transition with D3.`}</p>
    <p>{`That lets D3 control the timing, the easing functions, keeping it smooth, all
of that. You don't have to think about any of it.`}</p>
    <p>{`But instead of changing a DOM attribute, my tween calls `}<inlineCode parentName="p">{`this.setState`}</inlineCode>{` on the
React component. Meaning it's `}<em parentName="p">{`changing React state`}</em>{` instead.`}</p>
    <p><img parentName="p" {...{
        "src": "https://media.giphy.com/media/26ufdipQqU2lhNA4g/giphy.gif",
        "alt": null
      }}></img></p>
    <p>{`Since the donut bar chart knows how to render itself based on a radius ... well
... you can keep re-rendering at various radiuses and It Just Works.`}</p>
    <p>{`Smooth transition by re-rendering the whole visualization 60 times per second.
Even though it's a super complex component. Lots of moving parts and
subcomponents.`}</p>
    <p>{`Knowing everything that's going on behind the scenes I am `}<em parentName="p">{`blown away`}</em>{` by how
well it works.`}</p>
    <p>{`React is magic.`}</p>
    <p>{`But first you should understand custom tweens. They're the under-the-hood part of this magic.`}</p>
    <p><em parentName="p">{`This next section was recorded before hooks. The underlying technique remains the same. We'll do hooks in an exercise at the end of this chapter :)`}</em></p>
    <h1 {...{
      "id": "understanding-custom-tweens"
    }}>{`Understanding custom tweens`}</h1>
    <Vimeo id={438212092} mdxType="Vimeo" />
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/2018/transition-pie.gif",
        "alt": "A smoothly transitioning piechart"
      }}></img></p>
    <p>{`Transitions like we used above work using interpolators. For numbers, an
interpolator is easy: A function that parametrizes changes to a single
argument.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`let i = d3.interpolateNumber(10, 20)
i(0.0) // 10
i(0.2) // 12
i(0.5) // 15
i(1.0) // 20
`}</code></pre>
    <p>{`D3 can interpolate everything from numbers, to colors, and even objects or
strings. It does so by finding interpolatable parts of your argument and
manipulating them linearly depending on the `}<inlineCode parentName="p">{`t`}</inlineCode>{` parameter.`}</p>
    <p>{`But sometimes you need custom interpolators – tweens.`}</p>
    <p>{`Say you want to animate an arc shape. The path definition argument is a complex
string 👇`}</p>
    <pre><code parentName="pre" {...{}}>{`M100.6434055594246,-99.8203632756589A8,8,0,0,1,112.2823856114007,-99.46188154973098A150,150,0,0,1,-104.56274177607584,107.54828233063364A8,8,0,0,1,-104.38099615277264,95.90520136696549L-64.39381262786019,59.38549403963366A8,8,0,0,1,-53.635344263429694,59.35696964757701A80,80,0,0,0,61.78081312913049,-50.82451307295977A8,8,0,0,1,62.30830828934212,-61.57007978883599Z
`}</code></pre>
    <p>{`If that doesn't make sense, don't worry. I can't read it either.`}</p>
    <p>{`When you transition a shape like that, funny things can happen. Sometimes arcs
fly around the screen, sometimes you get an error.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/2018/naive-transition-d3-pie.gif",
        "alt": "Naively implemented transition. Notice the arcs change shape sometimes"
      }}></img></p>
    <p>{`Notice the arc wobble.`}</p>
    <h2 {...{
      "id": "tweens-to-the-rescue"
    }}>{`Tweens to the rescue`}</h2>
    <p>{`Luckily, D3 lets us define custom transitions called tweens. To smoothly
animate a piechart we can build an `}<inlineCode parentName="p">{`arcTween`}</inlineCode>{`. Because piecharts are made of
arcs.`}</p>
    <p>{`The idea is to move from transitioning path definitions, to transitioning
angles on a pie slice. We build a tween generator that takes some params and
returns a tweening function.`}</p>
    <p>{`Tweening functions lie behind all transitions. They take an argument, `}<inlineCode parentName="p">{`t`}</inlineCode>{`, and
return the value of your prop at a specific "time" of your transition. All
transitions you've used so far are built with tweens in the background.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/2018/transition-sketch.png",
        "alt": null
      }}></img></p>
    <p>{`Our tween generator is going to need:`}</p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`oldData`}</inlineCode>{`, the definition of our pie slice at the start of the transition`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`newData`}</inlineCode>{`, the definition of our pie slice that we want to tween towards`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`arc`}</inlineCode>{`, a
`}<a parentName="li" {...{
          "href": "https://github.com/d3/d3-shape/blob/master/README.md#arcs"
        }}>{`D3 arc generator`}</a></li>
    </ol>
    <p>{`Both `}<inlineCode parentName="p">{`oldData`}</inlineCode>{` and `}<inlineCode parentName="p">{`newData`}</inlineCode>{` come from a
`}<a parentName="p" {...{
        "href": "https://github.com/d3/d3-shape/blob/master/README.md#pies"
      }}>{`D3 pie generator`}</a>{`.
The `}<inlineCode parentName="p">{`startAngle`}</inlineCode>{` and `}<inlineCode parentName="p">{`endAngle`}</inlineCode>{` is what we're interested in.`}</p>
    <p>{`Our `}<inlineCode parentName="p">{`arcTween`}</inlineCode>{` function uses these to build a tween method that we then feed
into `}<inlineCode parentName="p">{`attrTween`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// inspired from http://bl.ocks.org/mbostock/5100636
function arcTween(oldData, newData, arc) {
  const copy = { ...oldData }
  return function () {
    const interpolateStartAngle = d3.interpolate(
        oldData.startAngle,
        newData.startAngle
      ),
      interpolateEndAngle = d3.interpolate(oldData.endAngle, newData.endAngle)

    return function (t) {
      copy.startAngle = interpolateStartAngle(t)
      copy.endAngle = interpolateEndAngle(t)
      return arc(copy)
    }
  }
}
`}</code></pre>
    <p>{`We make a `}<inlineCode parentName="p">{`copy`}</inlineCode>{` of `}<inlineCode parentName="p">{`oldData`}</inlineCode>{` so we don't change input data by accident, then
we return a function. This function creates two interpolators with
`}<inlineCode parentName="p">{`d3.interpolate`}</inlineCode>{`. One for each angle.`}</p>
    <p>{`Each interpolator starts from an `}<inlineCode parentName="p">{`oldData`}</inlineCode>{` angle and moves towards a `}<inlineCode parentName="p">{`newData`}</inlineCode>{`
angle.`}</p>
    <p>{`This function then returns our interpolation function. It takes the argument
`}<inlineCode parentName="p">{`t`}</inlineCode>{`, feeds it into our two interpolators, adjusts values on the `}<inlineCode parentName="p">{`copy`}</inlineCode>{` object,
feeds that into the `}<inlineCode parentName="p">{`arc`}</inlineCode>{` generator, and returns a new path definition.`}</p>
    <p>{`You use it like this 👇`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Arc.js
d3.select(this.refs.elem)
  .transition()
  .duration(80)
  .attrTween("d", arcTween(this.state.d, newProps.d, this.arc))
  .on("end", () =>
    this.setState({
      d: newProps.d,
      pathD: this.arc(newProps.d),
    })
  )
`}</code></pre>
    <p>{`Select an element, a `}<inlineCode parentName="p">{`<path>`}</inlineCode>{`, start a transition, make it last `}<inlineCode parentName="p">{`80`}</inlineCode>{`
milliseconds, `}<inlineCode parentName="p">{`attrTween`}</inlineCode>{` the path definition, `}<inlineCode parentName="p">{`d`}</inlineCode>{`, attribute using the tween
returned from `}<inlineCode parentName="p">{`arcTween`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "a-practical-example"
    }}>{`A practical example`}</h2>
    <Vimeo id={438211523} mdxType="Vimeo" />
    <p>{`Here's an example of how you might use that in an animated piechart. Same idea
works for any custom transition you want to build.`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/px7x26020/",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <Vimeo id={438211770} mdxType="Vimeo" />
    <h1 {...{
      "id": "use-tweens-to-drive-state--hybrid-animation"
    }}>{`Use tweens to drive state – hybrid animation`}</h1>
    <Vimeo id={438210970} mdxType="Vimeo" />
    <p>{`You now have all the ingredients for hybrid animation:`}</p>
    <ol>
      <li parentName="ol">{`Custom tweens for tight transition control`}</li>
      <li parentName="ol">{`State changes to drive animation`}</li>
    </ol>
    <p>{`Let's bring them together ✌️`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/optimistic-architecture-oluvf",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p>{`Red squares in a circle. Click one, they all fly out – bouncing like they're
tied to an elastic. Click again and the circle contracts.`}</p>
    <p>{`Silly example, sure, but a useful technique. Same way the piechart to bar chart
transition worked.`}</p>
    <p>{`It all happens in the `}<inlineCode parentName="p">{`<HybridExample />`}</inlineCode>{` component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<HybridExample width={500} height={500} x={250} y={250} />
`}</code></pre>
    <h2 {...{
      "id": "hybridexample-"
    }}><HybridExample mdxType="HybridExample" /></h2>
    <p>{`Hybrid example is a functional component based on React Hooks. We use state for
the `}<inlineCode parentName="p">{`radius`}</inlineCode>{`, an `}<inlineCode parentName="p">{`angleScale`}</inlineCode>{` to position rectangles, and an `}<inlineCode parentName="p">{`explode`}</inlineCode>{` function
to trigger the transition.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const HybridExample = ({ x, y }) => {
  const [radius, setRadius] = useState(10);
  const N = 10;

  const angleScale = d3
    .scaleLinear()
    .domain([0, N])
    .range([0, Math.PI * 2]);

  const explode = () => {
    // transition
  };

  return (
    <g transform={\`translate(\${x}, \${y})\`} onClick={explode}>
      {d3.range(N).map(i => (
        <Square
          x={Math.cos(angleScale(i)) * radius}
          y={Math.sin(angleScale(i)) * radius}
          key={i}
        />
      ))}
    </g>
  );
`}</code></pre>
    <p><inlineCode parentName="p">{`useState`}</inlineCode>{` hook gives us initial `}<inlineCode parentName="p">{`radius`}</inlineCode>{` of 10 and a `}<inlineCode parentName="p">{`setRadius`}</inlineCode>{` function to
change it. `}<inlineCode parentName="p">{`angleScale`}</inlineCode>{` translates linearly between a domain of 0 to N, and
angles in radians. 0 is 0, 2PI is a full circle.`}</p>
    <p>{`We render squares the usual way:`}</p>
    <ul>
      <li parentName="ul">{`grouping element for positioning`}</li>
      <li parentName="ul">{`loop over a range of size `}<inlineCode parentName="li">{`N`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<Square />`}</inlineCode>{` for each iteration`}</li>
      <li parentName="ul">{`use trigonometry to translate angles to `}<inlineCode parentName="li">{`x`}</inlineCode>{` and `}<inlineCode parentName="li">{`y`}</inlineCode>{` coordinates`}</li>
    </ul>
    <p>{`Here's a helpful image, if you've forgotten your high school maths like I have`}</p>
    <p><img parentName="p" {...{
        "src": "https://upload.wikimedia.org/wikipedia/commons/thumb/7/72/Sinus_und_Kosinus_am_Einheitskreis_1.svg/1920px-Sinus_und_Kosinus_am_Einheitskreis_1.svg.png",
        "alt": "sin for vertical, cos for horizontal"
      }}></img></p>
    <h2 {...{
      "id": "tween-driven-state-changes-for-animation"
    }}>{`Tween-driven state changes for animation`}</h2>
    <p>{`We used `}<inlineCode parentName="p">{`onClick={explode}`}</inlineCode>{` to trigger the animation. Here's what that looks
like`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const explode = () => {
  const elastic = d3.easeElasticOut
  elastic.amplitude(5)
  elastic.period(-0.3)

  d3.selection()
    .transition("radius-boom")
    .ease(elastic)
    .duration(1000)
    .tween("radius", () => {
      const radiusInt = d3.interpolate(radius, radius > 10 ? 10 : 60)
      return (t) => setRadius(radiusInt(t))
    })
}
`}</code></pre>
    <p><inlineCode parentName="p">{`explode`}</inlineCode>{` starts by creating a new easing function – `}<inlineCode parentName="p">{`elastic`}</inlineCode>{`. Elastic easing
is built into D3 and you can tweak its `}<inlineCode parentName="p">{`amplitude`}</inlineCode>{` and `}<inlineCode parentName="p">{`period`}</inlineCode>{`. Try different
values to see what happens.`}</p>
    <p>{`We start a new virtual `}<inlineCode parentName="p">{`d3.selection()`}</inlineCode>{`. Doesn't select anything, lets us run a
transition. Gotta give it a name though – `}<inlineCode parentName="p">{`radius-boom`}</inlineCode>{`.`}</p>
    <p>{`Then we specify the easing function, the duration, and create a custom tween.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`.tween("radius", () => {
    const radiusInt = d3.interpolate(radius, radius > 10 ? 10 : 60);
    return t => setRadius(radiusInt(t));
});
`}</code></pre>
    <p>{`Our tween operates on the `}<inlineCode parentName="p">{`radius`}</inlineCode>{`, which isn't even an SVG property. That's
just a logical name we use.`}</p>
    <p>{`The tween itself uses an interpolator that goes from current radius – that's
the state – to either `}<inlineCode parentName="p">{`10`}</inlineCode>{` or `}<inlineCode parentName="p">{`60`}</inlineCode>{`. Small or large. That way we can use the
same tween both for growing and shrinking the circle.`}</p>
    <p>{`We return a parametrized function that takes a `}<inlineCode parentName="p">{`t`}</inlineCode>{` parameter and calls
`}<inlineCode parentName="p">{`setRadius`}</inlineCode>{` with a value from our interpolator.`}</p>
    <p><inlineCode parentName="p">{`setRadius`}</inlineCode>{` is the set state function we defined earlier with `}<inlineCode parentName="p">{`useState`}</inlineCode>{`, which
means we update state on every tick of the transition animation. Update state,
component re-renders, you get smooth animation.`}</p>
    <p>{`Works great ✌️`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/2018/state-tween.gif",
        "alt": null
      }}></img></p>
    <h2 {...{
      "id": "a-bouncy-scatterplot-exercise"
    }}>{`A bouncy scatterplot exercise`}</h2>
    <p>{`Time for an exercise, with hooks this time :)`}</p>
    <Vimeo id={438235934} mdxType="Vimeo" />
    <p>{`Take your scatterplot from a few chapters ago and change the click handling function to resize the scatterplot using hybrid animation. Give it a little bounce.`}</p>
    <p>{`You can use mine:`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/react-d3-scatterplot-solved-kg107",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p>{`Once you gave that a shot, check my solution below.`}</p>
    <h3 {...{
      "id": "my-solution"
    }}>{`My solution`}</h3>
    <Vimeo id={438236321} mdxType="Vimeo" />
    <iframe {...{
      "src": "https://codesandbox.io/embed/react-d3-scatterplot-bouncy-q67tk",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p>{`The key difference is this part here:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function resize() {
  d3.selection()
    .transition()
    .duration(2000)
    .ease(d3.easeBounceOut)
    .tween("dimensions", () => {
      const width = d3.interpolate(dimensions.width, 0)
      const height = d3.interpolate(dimensions.height, 0)

      return (t) => {
        setDimensions({
          width: width(t),
          height: height(t),
        })
      }
    })
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      